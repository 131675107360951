<template>
  <g :fill="color" fill-rule="evenodd">
    <path
      fill-rule="nonzero"
      d="M18.643 20.488H2.357A1.856 1.856 0 0 1 .5 18.63V7.781c0-1.025.831-1.856 1.857-1.856h16.286c1.026 0 1.857.831 1.857 1.856v10.85a1.856 1.856 0 0 1-1.857 1.857zm0-1a.856.856 0 0 0 .857-.857V7.781a.856.856 0 0 0-.857-.856H2.357a.856.856 0 0 0-.857.856v10.85c0 .473.383.857.857.857h16.286z"
    />
    <path d="M17.286 13.206a1.356 1.356 0 1 1-2.713 0 1.356 1.356 0 0 1 2.713 0" />
    <path
      fill-rule="nonzero"
      d="M15.429 1.738v4.687a.5.5 0 0 0 1 0V1a.5.5 0 0 0-.686-.464L2.172 5.96a.5.5 0 0 0 .37.928L15.43 1.74z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
